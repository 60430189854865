<template>
  <div class="account" style="padding-bottom: 100px; background-color: #e3ebf7">
    <top-fixed></top-fixed>

    <!--background-color: #1d76fb;  -->
    <div
      style="
        width: 7.5rem;
        height: 7.8rem;
        color: #fff;
        border-radius: 0 0 10px 10px;

        background-color: #1d76fb;
      "
    >
      <div style="display: flex; justify-content: center; align-items: center">
        <img style="width: 3.5rem" src="../assets/img/acooutbg.png" alt="" />
      </div>
      <div>
        <div style="text-align: center; margin-top: 0.2rem">
          {{ $t("hj2") }}(USDT)
        </div>
        <div
          style="
            text-align: center;
            font-size: 34px;
            font-weight: bold;
            margin-top: 0.2rem;
          "
        >
          {{ useData.usdt_balance }}
        </div>
      </div>

      <div
        style="display: flex; padding: 0.25rem; justify-content: space-between"
      >
        <div style="text-align: center; margin-top: 0.3rem">
          <div style="color: #e3ebf7">{{ $t("hj3") }}(ETH)</div>
          <div style="margin-top: 0.23rem; font-size: 24px">
            {{ Number(useData.available_eth_profit).toFixed(2) }}
          </div>
        </div>
        <div style="text-align: center; margin-top: 0.3rem">
          <div style="color: #e3ebf7">{{ $t("hj28") }}(ETH)</div>
          <div style="margin-top: 0.23rem; font-size: 24px">
            {{ Number(useData.total_eth_profit).toFixed(2) }}
          </div>
        </div>
        <div style="text-align: center; margin-top: 0.3rem">
          <div style="color: #e3ebf7">{{ $t("hj4") }}(USDT)</div>
          <div style="margin-top: 0.23rem; font-size: 24px">
            {{ Number(useData.token_balance).toFixed(2) }}
          </div>
        </div>
      </div>

      <div
        @click="$router.push('/reward')"
        style="
          border: 1px solid #e3ebf7;
          color: #e3ebf7;
          width: 2.5rem;
          height: 0.6rem;
          margin: 0.18rem auto;
          border-radius: 10px;
          line-height: 0.6rem;
          text-align: center;
        "
      >
        {{ $t("hj5") }}
        <img
          style="width: 0.2rem; height: 0.2rem"
          src="../assets/img/right.png"
          alt=""
        />
      </div>
    </div>

    <div
      style="
        margin: -0.5rem 0.25rem;
        padding: 0.3rem;
        background-color: #fff;
        border-radius: 10px;
      "
      v-if="type == 1"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          margin-top: 0.11rem;
        "
      >
        <div>{{ $t("hj6") }}</div>
        <div
          @click="type = 2"
          style="color: #1d76fb; display: flex; align-items: center"
        >
          $
          <span>{{ $t("hj7") }}</span>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-top: 0.35rem;
          height: 0.8rem;
          align-items: center;
          padding: 0 0.3rem;
          border: 1px solid #cac9c9;
          border-radius: 4px;
        "
      >
        <div>USDT</div>
        <div>
          <input
            type="text"
            v-model="txMoney"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          />
        </div>
        <div style="display: flex; align-items: center">
          <span @click="allBut">{{ $t("hj8") }}</span>
          <img
            style="width: 0.2rem; margin-left: 0.2rem"
            src="../assets/img/arrow_bottom.png"
            alt=""
          />
        </div>
      </div>

      <div
        @click="withdraw"
        style="
          height: 0.8rem;
          line-height: 0.8rem;
          margin-top: 0.6rem;
          text-align: center;
          font-size: 18px;
          margin-bottom: 0.3rem;
          background-color: #1d76fb;
          color: #fff;
          border-radius: 4px;
        "
      >
        {{ $t("hj6") }}
      </div>
    </div>

    <div
      style="
        margin: -0.5rem 0.25rem;
        padding: 0.3rem;
        background-color: #fff;
        border-radius: 10px;
      "
      v-if="type == 2"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          margin-top: 0.11rem;
        "
      >
        <div>{{ $t("hj7") }}</div>
        <div
          @click="type = 1"
          style="color: #1d76fb; display: flex; align-items: center"
        >
          <img
            src="../assets/img/tixian.png"
            style="width: 0.4rem; margin-right: 0.2rem"
            alt=""
          />
          <span>{{ $t("hj6") }}</span>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-top: 0.35rem;
          height: 0.8rem;
          align-items: center;
          padding: 0 0.3rem;
          border: 1px solid #cac9c9;
          border-radius: 4px;
        "
      >
        <div>ETH</div>
        <div>
          <input type="text" v-model="dhMoney" />
        </div>
        <div @click="allBut" style="display: flex; align-items: center">
          <span>{{ $t("hj8") }}</span>
          <img
            style="width: 0.2rem; margin-left: 0.2rem"
            src="../assets/img/arrow_bottom.png"
            alt=""
          />
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-top: 0.3rem;
          height: 0.8rem;
          align-items: center;
          padding: 0 0.3rem;
          border: 1px solid #cac9c9;
          border-radius: 4px;
        "
      >
        <div>USDT</div>

        <div>{{ dhUsdt }}</div>
      </div>

      <div
        @click="exchange"
        style="
          height: 0.8rem;
          line-height: 0.8rem;
          margin-top: 0.6rem;
          text-align: center;
          font-size: 18px;
          margin-bottom: 0.3rem;
          background-color: #1d76fb;
          color: #fff;
          border-radius: 4px;
        "
      >
        {{ $t("hj7") }}
      </div>
    </div>

    <bottom-fixed></bottom-fixed>
  </div>
</template>


<script>
import BottomFixed from "../components/BottomFixed.vue";
import TopFixed from "../components/TopFixed.vue";
import { account, withdraw, exchange } from '../http/api'
import { Toast } from 'vant-green';
export default {
  name: "AccountView",
  data () {
    return {
      type: 1, // 1提现 2//兑换
      useData: "",
      dhMoney: '',
      txMoney: '',
      dhUsdt: ''
    }
  },
  components: { BottomFixed, TopFixed },
  watch: {
    dhMoney () {
      this.dhUsdt = this.dhMoney * window.localStorage.getItem("exchange_rate")
    }
  },

  methods: {
    fetch_Account () {
      account().then(res => {
        this.useData = res.data
      })
    },
    allBut () {
      this.txMoney = parseInt(this.useData.usdt_balance)
      this.dhMoney = this.useData.available_eth_profit
      this.dhUsdt = this.useData.available_eth_profit * window.localStorage.getItem("exchange_rate")
    },

    withdraw () {
      withdraw({ usdt_amount: this.txMoney }).then((res) => {
        this.fetch_Account()
        Toast({
          message: res.msg
        });
      })
    },

    exchange () {
      var data = {
        eth_amount: this.useData.available_eth_profit,
        usdt_amount: this.dhUsdt,
        exchate_rate: window.localStorage.getItem("exchange_rate")
      }
      exchange({ data }).then((res) => {
        this.fetch_Account()
        Toast({
          message: res.msg
        });
      })
    }
  },
  mounted () {
    this.fetch_Account()
  }

};

</script>
<style scoped>
.account {
  /* background-image: url("../assets/img/2-账号.png"); */
  background-size: 7.5rem;

  width: 7.5rem;
  height: 15rem;
  /* background-position-y: -51px; */
}
input {
  background: none;
  outline: none;
  border: none;
}
</style>
